import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import { GatsbyBrowser } from "gatsby"
import React, { useEffect } from "react"

import Layout from "./src/components/layout"

// Wraps every page in a component
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: innerProps,
}) => {
  const shouldDisplayNav =
    innerProps.pageContext?.currentPageTemplate !== "landing-template"

  const shouldDisplayAnchors =
    innerProps.pageContext?.currentPageTemplate === "ecommerce-template"

  let shouldDisplayCta = true

  if (
    innerProps.pageContext?.currentPageTemplate === "book-a-demo-template" ||
    innerProps.pageContext?.currentPageTemplate === "contact-template"
  ) {
    shouldDisplayCta = false
  }

  // Handle anchor behavior (smooth scroll with an offset of 90px)
  // This block is browser-only, as SSR doesn't have access to window
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    const anchorSelector = anchor.getAttribute("href")

    if (!anchorSelector || anchorSelector === "#") return null

    anchor.addEventListener("click", function (e) {
      e.preventDefault()

      const scrollTarget = document
        .querySelector(anchorSelector)
        .getBoundingClientRect().top
      const scrollTargetAdjusted = scrollTarget + window.scrollY - 110

      window.scrollTo({
        behavior: "smooth",
        top: scrollTargetAdjusted,
      })
    })
  })

  return (
    <>
      <Layout
        {...innerProps}
        displayAnchors={shouldDisplayAnchors}
        displayCta={shouldDisplayCta}
        displayNav={shouldDisplayNav}>
        {element}
      </Layout>
    </>
  )
}

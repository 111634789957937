import colorVars from "@/assets/styles/colorVars"
import { isNotEmpty } from "ramda-extension"
import React, { FC } from "react"
import { up } from "styled-breakpoints"
import styled, { css } from "styled-components"

export type customBgProps = {
  blurs?: Array<blurProps>
  className?: string
}

export type blurProps = {
  blur?: string
  color?: string
  defaultBlue?: boolean
  defaultPink?: boolean
  opacity?: number
  pos?: PositionProps
  size?: number | string
}

type PositionProps = {
  bottom?: number | string
  left?: number | string
  right?: number | string
  top?: number | string
}

export const CustomBgWrapper = styled.div<customBgProps>`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
`

export const Blur = styled.div<blurProps>`
  position: absolute;
  top: ${(props) => props.pos?.top ?? "initial"};
  right: ${(props) => props.pos?.right ?? "39px"};
  bottom: ${(props) => props.pos?.bottom ?? "88px"};
  left: ${(props) => props.pos?.left ?? "initial"};

  width: 2px;
  height: 2px;
  filter: blur(${(props) => props.blur ?? "100px"});

  border-radius: 100%;
  opacity: ${(props) => props.opacity ?? "0.07"};
  transform: translate3d(0, 0, 0);

  ${(props) => css`
    box-shadow: 0px 0px ${props.blur ?? "233px"} ${props.size ?? "233px"}
      ${props.color ?? colorVars.pink.main};
    background-color: ${props.color ?? colorVars.pink.main};
  `}

  ${up("md")} {
    ${(props) => css`
      background-color: ${props.color ?? colorVars.pink.main};
      box-shadow: 0 0 ${props.blur ?? "233px"} ${props.size ?? "233px"}
        ${props.color ?? colorVars.pink.main};
    `}
  }
  ${up("lg")} {
    ${(props) => css`
      background-color: ${props.color ?? colorVars.pink.main};
      box-shadow: 0 0 ${props.blur ?? "233px"} ${props.size ?? "233px"}
        ${props.color ?? colorVars.pink.main};
    `}
  }

  ${(props) =>
    props.defaultBlue &&
    css`
      && {
        top: 158px;
        left: 122px;
        background-color: ${colorVars.teal.main};
        box-shadow: 0 0 ${props.blur ?? "266px"} ${props.size ?? "266px"}
          ${colorVars.teal.main};
      }
    `}
  ${(props) =>
    props.defaultPink &&
    css`
      /* stylelint-disable-next-line */
      && {
        top: 158px;
        left: 122px;
        background-color: ${colorVars.pink.main};
        box-shadow: 0 0 ${props.blur ?? "266px"} ${props.size ?? "266px"}
          ${colorVars.pink.main};
      }
    `}
`

export const CustomBackground: FC<customBgProps> = (props) => {
  return (
    <CustomBgWrapper {...props} className={props.className}>
      {props?.blurs &&
        isNotEmpty(props?.blurs) &&
        props.blurs.map((blur: any, index: any) => (
          <Blur {...blur} key={`custom-bg-blur-${index}`} />
        ))}
    </CustomBgWrapper>
  )
}

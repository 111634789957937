import { GlobalStyle } from "@/assets/styles/globalStyle"
import FooterSection from "@/sections/footer"
import HeaderSection from "@/sections/header"
import { useWindowSize } from "@/utilities/helpers"
import { graphql, useStaticQuery } from "gatsby"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import React, { FC, createContext, useEffect, useState } from "react"
import styled from "styled-components"

export const GlobalWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`

// Create a UiContext to pass down the isMenuOpen and isFloating states
export const UiContext = createContext<any>({
  isFloating: false,
  isMenuOpen: false,
  toggleMenuOpen: () => undefined,
})

export const Layout: FC<any> = (props: any) => {
  const { children, displayAnchors, displayCta, displayNav } = props

  // Handle SEO static querying and pass it down to the SEOContext
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }

            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
              cardType
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)

  // Check for any scroll and set the header floating state
  const [isFloating, setIsFloating] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") return

    let scrollOffset

    window.addEventListener("scroll", () => {
      scrollOffset = window.scrollY
      if (scrollOffset > 5) {
        setIsFloating(true)
      } else {
        setIsFloating(false)
      }
    })
  }, [])

  // Handle opening and closing the mobile menu
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // If we're no longer on a smaller viewport, close the mobile menu
  const { width: windowSize } = useWindowSize()
  useEffect(() => {
    if (windowSize >= 768) {
      setIsMenuOpen(false)
    }
  }, [windowSize, isMenuOpen])

  // Handle menu toggle button clicks
  const toggleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // Handle clicking in menu items themselves (anchors and links)
  useEffect(() => {
    const handleClick = (event: any) => {
      // If the click happens on an <a> element
      if (event.target.tagName === "A") {
        setIsMenuOpen(false)
        return
      }

      // Check if click happens outside <header> element and not on .header-content-toggler
      if (
        !event.target.closest("header") &&
        !event.target.closest(".header-content-toggler")
      ) {
        setIsMenuOpen(false)
      }
    }

    if (typeof window !== "undefined") {
      window.addEventListener("click", handleClick)
      return () => window.removeEventListener("click", handleClick)
    }
  }, [])

  return (
    <>
      <SEOContext.Provider value={{ global: seo }}>
        <UiContext.Provider value={{ isFloating, isMenuOpen, toggleMenuOpen }}>
          <GlobalStyle />
          {displayNav && (
            <HeaderSection
              displayAnchors={displayAnchors}
              displayCta={displayCta}
              isFloating={isFloating}
            />
          )}
          <GlobalWrapper>
            {children}
            {displayNav && <FooterSection />}
          </GlobalWrapper>
        </UiContext.Provider>
      </SEOContext.Provider>
    </>
  )
}

export default Layout

import { styleVars } from "@/assets/styles/styleVars"
import { getImage } from "gatsby-plugin-image"
import parse, { HTMLReactParserOptions } from "html-react-parser"
import { isEmpty } from "ramda"
import { useEffect, useState } from "react"

export const importScript = (resourceUrl: string, resourceID?: string) => {
  useEffect(() => {
    let alreadyImported = false

    if (resourceID) {
      const importScript = document.getElementById(resourceID)
      if (importScript) {
        alreadyImported = true
      }
    }

    if (!alreadyImported) {
      const script = document.createElement("script")
      script.src = resourceUrl
      script.id = resourceID || ""
      script.async = true
      document.body.appendChild(script)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [resourceUrl])
}

/**
 * Convert a `wpMediaItem` into an image.
 */
export function getImageFromMediaItem(image: Queries.WpMediaItem | undefined) {
  return image && image.localFile
    ? getImage(image.localFile as any)
    : image?.gatsbyImage
    ? image.gatsbyImage
    : null
}

/**
 *
 * HOOK to load Marketo Form async
 *
 * source: https://github.com/charliedieter/react-marketo-hook
 *
 * @param baseUrl base marketo js file location
 * @param setScriptLoaded if loaded
 * @returns nothing
 */
const appendMktoScript = (baseUrl?: string, setScriptLoaded?: any) => {
  if (window.MktoForms2) return setScriptLoaded(true)

  const script = document.createElement("script")
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null)
  document.body.appendChild(script)
}

type IMarketoData = {
  baseUrl?: string
  callback?: any
  formId?: string
  munchkinId?: string
}

export const useMarketo = ({
  baseUrl,
  callback,
  formId,
  munchkinId,
}: IMarketoData) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [loadedForm, setLoadedForm] = useState(false)
  useEffect(() => {
    if (scriptLoaded && !loadedForm) {
      window.MktoForms2.setOptions({
        formXDPath: "/rs/808-SHH-991/images/marketo-xdframe-relative.html",
      })
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback)
      setLoadedForm(true)
      return
    }
    appendMktoScript(baseUrl, setScriptLoaded)
  }, [scriptLoaded, baseUrl, munchkinId, formId, callback])
}

export const secondsToTime = (e: number) => {
  const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0")

  return h + ":" + m + ":" + s
  //return `${h}:${m}:${s}`;
}

/**
 *  Helper function to check and add fallbacks for SEO
 *  - currently only adds fallback to description
 *  */

export const checkSeo = (pageData?: any, fallbackDesc?: string) => {
  // Remove HTML Tags
  fallbackDesc = fallbackDesc ? fallbackDesc.replace(/(<([^>]+)>)/gi, "") : ""

  if (
    pageData?.seo &&
    (!pageData?.seo?.metaDesc || pageData?.seo?.metaDesc === "")
  ) {
    const descMaxLength = 120
    const threeDots = fallbackDesc.length > descMaxLength ? "..." : ""
    const fallbackDescription =
      (fallbackDesc &&
        fallbackDesc.substring(
          0,
          fallbackDesc.lastIndexOf(" ", descMaxLength),
        ) + threeDots) ||
      ""
    pageData.seo.metaDesc = fallbackDescription
  }

  if (pageData.seo) {
    // OPENGRAPH DESCRIPTION
    pageData.seo.opengraphDescription =
      pageData.seo.opengraphDescription === ""
        ? pageData.seo.metaDesc
        : pageData.seo.opengraphDescription

    // TWITTER DESCRIPTION
    pageData.seo.twitterDescription =
      pageData.seo.twitterDescription === ""
        ? pageData.seo.metaDesc
        : pageData.seo.twitterDescription
  } else {
    return false
  }

  return pageData
}

/***
 *
 * Fisher–Yates ARRAY SHUFFLE ALGORITHM: https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
 *
 */
export const shuffle = (array: Array<any>) => {
  const newArray = [...array]
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex],
      newArray[currentIndex],
    ]
  }

  return newArray
}

// HELPER FOR DEFAULT CTA SECTION
export const getDefaultCtaData = (ctaData: any) => {
  if (ctaData) {
    return {
      button: {
        link: ctaData.link,
        title: ctaData.link.title,
      },
      heading: {
        subtitle: ctaData.subtitle,
        title: ctaData.title,
      },
    } as any
  }
}

// WINDOW SIZE CUSTOM HOOK
// source: https://usehooks.com/useWindowSize/
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<any>({
    width: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      })
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

// GOOGLE TAG MANAGER (GTM) DATALAYER EVENT PUSH
export type gtmEventProps = {
  eventData?: Record<string, unknown>
  eventName?: string
}

export const sendGtmEvent = (gtmData?: gtmEventProps): void => {
  if (gtmData && !isEmpty(gtmData)) {
    if (gtmData?.eventName && gtmData?.eventName !== "") {
      // Push GTM event to dataLayer (for GA4)
      const dataLayer = window.dataLayer || []

      let gtmPayload = { event: gtmData?.eventName }

      if (gtmData?.eventData && !isEmpty(gtmData?.eventData)) {
        // Add any data in the eventData object
        gtmPayload = { ...gtmPayload, ...gtmData?.eventData }
      }

      if (typeof window !== "undefined" && dataLayer) {
        dataLayer.push(gtmPayload)
      }
    }
  }
}

// CHECK IF A VARIABLE IS AN OBJECT
export const isObject = (value: any) => {
  return !!(value && typeof value === "object" && !Array.isArray(value))
}

// Get correct Bg Color from string
export const handleBackgroundColorType = (type?: string) => {
  switch (type) {
    case "green":
      return styleVars.colors.seaGreen
    case "sage-green":
      return styleVars.colors.sageGreen
    case "blue":
      return styleVars.colors.skyBlue
    case "purple":
      return styleVars.colors.purple
    default:
      return styleVars.colors.pink
  }
}

/**
 * Variant Style checker - uses Generic types to pass proper type/interface context on
 * use. This means that this function can be reused anywhere without code duplication.
 * @param props - <T> defines the shape of props expected on the called context
 * @param variantStyle - <S | Array<S>> defines which variantStyles exist on that context
 * @returns boolean - true if variantStyle is found, false if not
 */
export const checkVariantStyle = <
  T extends { variantStyle?: S; variantStyles?: Array<S> },
  S,
>(
  props: T,
  variantStyle: S,
): boolean =>
  props?.variantStyles?.includes(variantStyle) ||
  props?.variantStyle === variantStyle

export function debounce<T extends (...args: Array<any>) => any>(
  ms: number,
  callback: T,
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timer: NodeJS.Timeout | undefined

  return (...args: Parameters<T>) => {
    if (timer) {
      clearTimeout(timer)
    }
    return new Promise<ReturnType<T>>((resolve) => {
      timer = setTimeout(() => {
        const returnValue = callback(...args) as ReturnType<T>
        resolve(returnValue)
      }, ms)
    })
  }
}

export function getPositionX(event: any) {
  return event.type.includes("mouse") ? event.pageX : event.touches[0].clientX
}

export function getElementDimensions(ref: any) {
  const width = ref.clientWidth
  const height = ref.clientHeight
  return { height, width }
}

export const flatListToHierarchical = (
  menuData: Array<any> = [],
  { childrenKey = "children", idKey = "id", parentKey = "parentId" } = {},
) => {
  const tree: Array<any> = []
  const childrenOf: any = {}
  menuData.forEach((item) => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).unshift(newItem)
      : tree.unshift(newItem)
  })
  return tree
}

/**
 * Create an array range.
 *
 * @param start start position
 * @param end end position
 * @param step step between positions
 * @returns range
 */
export const range = (start: number, end: number, step = 1) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start) / step)).keys()),
    (x) => start + x * step,
  )
}

export const replaceWpLinks = (content: any, justURL?: boolean) => {
  const WP_URL = "https://" + process.env.GATSBY_API_URL?.split("/")[0]
  let prefix = 'href="'

  if (justURL) {
    prefix = ""
  }

  if (
    typeof content === "string" &&
    content !== "" &&
    content.includes(WP_URL)
  ) {
    const toReplace = new RegExp(prefix + WP_URL, "g")
    content = content.replace(toReplace, prefix + process.env.GATSBY_URL)
  }

  return content
}

// Hook to handle click outside
export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  )
}

/**
 *  This function captures a string in the article body
 *  and replaces it for a CTA Block
 *
 *  Example of the String:
 *  [cta title="This is a Title" link="xxx" name="CTA"]
 */
const ctaParsing = (Component: any, childrenData: any) => {
  // eslint-disable-next-line
  const parsedData = childrenData.replaceAll('“', '”')
  const getBetweenWhiteSpaces = /\s+(?=([^”]*”[^”]*”)*[^”]*$)/g
  const strArr = parsedData.split(getBetweenWhiteSpaces)
  const targetBlank = strArr.includes("newTab")

  const strArrNoCommas: Array<Array<string>> = strArr
    .slice(1)
    .map((element: any) => element && element.split("="))

  const ctaData: any = strArrNoCommas.reduce((acc: any, val: Array<string>) => {
    const key = val && val.length >= 1 ? val[0].trim() : ""
    const getStringBetweenQuotes = /((?:[^"'”\\])+)/g

    const value =
      val && val.length >= 1 ? val[1].match(getStringBetweenQuotes) || [] : []

    acc[key] = value.length >= 1 && value[0] ? value[0] : ""
    return acc
  }, {})

  ctaData["targetBlank"] = targetBlank

  const buttonCTAData: any = {
    id: "gtm_click",
    link: {
      target: ctaData["targetBlank"] ? "_blank" : "",
      title: ctaData["name"],
      url: ctaData["link"],
    },
    title: ctaData["name"],
    type: "url",
    variantStyle: "m",
  }

  return (
    <Component
      button={buttonCTAData}
      secondTitle={ctaData["secondTitle"]}
      title={ctaData["title"]}
    />
  )
}

const codeBlockParsing = (
  Component: any,
  childrenData: any,
  Theme: any,
  lang: any,
) => {
  return (
    <Component
      codeBlock
      language={lang && lang !== "" ? lang : "jsx"}
      showLineNumbers={true}
      style={Theme}
      wrapLines>
      {childrenData}
    </Component>
  )
}

interface IOptionsComponents {
  Component: any
  theme?: any
}
type IOPtions = {
  code: IOptionsComponents
  cta: IOptionsComponents
}

export const parseBody = (options: IOPtions, content: any) => {
  const bodyParsing: HTMLReactParserOptions = {
    replace: (domNode) => {
      const { code, cta } = options

      if ("name" in domNode && "attribs" in domNode) {
        const children: any = domNode.children ? domNode.children[0] : undefined
        const preChildren: any =
          children && children.children ? children.children[0] : undefined

        let childrenData: string = children?.data || preChildren?.data

        if (domNode.name === "p" && childrenData) {
          if (childrenData.includes("[cta")) {
            const result = ctaParsing(cta.Component, childrenData)
            return result
          }
        } else if (
          domNode.name === "pre" ||
          domNode.attribs?.class === "wp-block-code"
        ) {
          const regexLang = /\[lang="(.*?)"]/
          const regexAll = /(\[lang=")(.*)("])/
          let lang: any = ""
          if (childrenData) {
            lang = childrenData.match(regexLang)
            childrenData = childrenData.replace(regexAll, "")

            if (lang && lang.length >= 2) {
              lang = lang[1]
            } else {
              lang = ""
            }
          }

          const result = codeBlockParsing(
            code.Component,
            childrenData,
            code.theme,
            lang,
          )
          return result
        }
      }
    },
  }
  return parse(content, bodyParsing)
}

/**
 * Parse <break> substring into a new line
 * @param input string
 * @param newLine boolean - if true, it will use \n instead of <br />
 * @returns string
 */
export const convertBreakToNewLine = (
  input?: string,
  newLine?: boolean,
): null | string => {
  const replacer = newLine ? " \n" : " <br />"
  return input ? input.replace(/<break>/g, replacer) : null
}

import colorVars from "@/assets/styles/colorVars"
import React, { FC } from "react"
import styled, { css } from "styled-components"

import { styleVars } from "../../assets/styles/styleVars"
import { replaceWpLinks } from "../../utilities/helpers"

type ButtonType = "action" | "anchor" | "url"

export interface IButton {
  action?: any
  anchor?: string
  center?: boolean
  children?: any
  className?: string

  disabled?: boolean
  icon?: string
  id?: string
  link?: any
  nativeType?: "button" | "reset" | "submit"
  ref?: any

  rel?: string
  title?: string
  type: ButtonType
}

export const ButtonBaseStyle: any = css`
  position: relative;
  z-index: 1;

  display: inline-flex;
  align-items: flex-end;
  align-self: baseline;

  width: auto;
  padding: 14px 20px;
  overflow: hidden;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: white;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  cursor: pointer;
  background: ${colorVars.blue.main};
  border: none;
  border-radius: 8px;
  outline: none;

  transition: background 400ms ease, color 400ms ease, border-color 400ms ease;

  &&:disabled {
    cursor: default;
    opacity: 0.42;
  }

  &&:focus,
  &&:active {
    box-shadow: 0 0 0 2px ${styleVars.colors.textLightGray};
  }
`

export const ButtonWrapper = styled.a<IButton>`
  ${ButtonBaseStyle}

  ${(props) =>
    props.center &&
    css`
      align-self: center;
      margin: 0 auto;
    `}
`

export const Button: FC<IButton> = (props) => {
  const data = { ...props }

  // no title or icon so don't render button
  if (!data?.title && !data?.link?.title && !data?.icon) {
    return null
  }

  if (typeof data.link !== "string" && data.link?.url) {
    data.link.url = replaceWpLinks(data.link.url, true)
  }

  const linkProps: any =
    props.type !== "action"
      ? {
          href: data.type === "anchor" ? `#${data.anchor}` : data.link.url,
          target: data.link?.target ?? "_self",
        }
      : {
          as: "button",
          disabled: props.disabled,
          onClick: data.action as any,
          type: props.nativeType ?? "button",
        }

  const title = data.type === "url" ? data?.link?.title : data?.title

  return (
    <ButtonWrapper
      className={data.className + " button" || "button"}
      {...linkProps}
      center={data.center}
      id={data.id}
      rel={data.rel}>
      <>{title || data.children}</>
    </ButtonWrapper>
  )
}

export const BlueButton = styled(Button)`
  background: ${colorVars.blue.main};
  border: 1px solid rgba(0, 0, 0, 0.3);

  &&:focus,
  &&:active,
  &&:hover {
    background: ${colorVars.blue.buttonHover};
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
`

export const GreyButton = styled(Button)`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);

  &&:focus,
  &&:active,
  &&:hover {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`

export const TextButton = styled(Button)`
  padding: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: ${colorVars.text.gray};
  background: none;

  &&:focus,
  &&:active,
  &&:hover {
    color: white;
  }
`

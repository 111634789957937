import HeaderNavBorderBg from "@/assets/images/nav-border-bg@2x.png"
import colorVars from "@/assets/styles/colorVars"
import MenuMobileClose from "@/assets/svg/ui/ic_menu_mobile_close.inline.svg"
import MenuMobileOpen from "@/assets/svg/ui/ic_menu_mobile_open.inline.svg"
import { CustomBackground, blurProps } from "@/components/bg/blur-bg"
import { BlueButton, TextButton } from "@/components/button"
import { BaseImage } from "@/components/image/base-image"
import { UiContext } from "@/components/layout"
import { Spacer } from "@/components/spacer"
import { Link, StaticQuery, graphql } from "gatsby"
import { isNotEmpty } from "ramda-extension"
import React, { FC, useContext } from "react"
import { up } from "styled-breakpoints"
import styled, { css } from "styled-components"

export interface IHeaderProps {
  cta?: any
  data?: any
  displayAnchors?: boolean
  displayCta?: boolean
  isFloating?: boolean
  isMenuOpen?: boolean
  logo?: Queries.WpMediaItem
  navItems?: any
}

const HeaderContainerFloatingStyle = css`
  background-color: rgb(255 255 255 / 1%);
  border: 0;
  border-bottom: 1px solid rgb(247 248 248 / 10%);
`

const HeaderContainerOpenedStyle = css`
  border-radius: 0 0 24px 24px;
  border-image-source: url(${HeaderNavBorderBg});
  border-image-slice: fill 48;
  border-image-width: 24px;
  box-shadow: 0 8px 16px black;
`

const HeaderContainer = styled.header<IHeaderProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  min-height: 70px;
  padding: 0 16px;
  overflow-x: visible;
  overflow-y: visible;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid transparent;
  backdrop-filter: blur(20px);
  transition: background-color 150ms ease, border-bottom-color 150ms ease;

  ${up("md")} {
    height: 70px;
  }

  /* Pass this prop whenever the user scrolls past 0px - the bar changes slightly visually */
  ${(props) =>
    props.isFloating &&
    css`
      ${HeaderContainerFloatingStyle}
    `}

  /* When the menu is opened, add a different effect */
  ${(props) =>
    props.isMenuOpen &&
    css`
      ${HeaderContainerOpenedStyle}
    `}
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: space-between;
  width: 100%;
  height: 100%;
  min-height: 70px;
  padding-top: 20px;

  ${up("md")} {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
  }

  ${up("lg")} {
    max-width: 1120px;
    margin: 0 auto;
  }
`

const HeaderLogoLink = styled(Link)`
  max-width: 180px;
`

const HeaderLogoImg = styled(BaseImage)`
  left: 5px;
  width: 110px;
  pointer-events: none;

  ${up("md")} {
    left: 0;
    width: 120px;
  }
`

const HeaderNav = styled.ul`
  position: relative;
  display: flex;
  gap: 0 32px;
  place-items: center;
  margin-left: auto;

  ${up("md")} {
    padding-left: 0;
  }
`

const HeaderNavItem = styled.li`
  list-style-type: none;
`

const HeaderNavItemAnchor = styled(TextButton)`
  &:not(:focus-visible) {
    box-shadow: none !important;
  }
`

const HeaderCTA = styled(BlueButton)`
  align-self: center;
  max-width: 400px;
  padding: 13px 20px;
  margin-left: auto;
`

const HeaderContentToggler = styled.button`
  position: fixed;
  top: 21px;
  right: 12px;
  z-index: 20;
  display: block;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;

  ${up("md")} {
    display: none;
  }

  > svg {
    pointer-events: none;
  }
`

const HeaderContentContainerMobileStyle = css`
  display: flex;
  flex-flow: column;
  padding: 0 0 16px;

  ${HeaderNav} {
    flex-flow: column;
    place-items: start;
    padding: 32px 0;
    margin: 0;

    ${HeaderNavItem} {
      padding: 16px 0;
    }

    &::before {
      position: absolute;
      top: 25px;
      left: -15px;
      display: block;
      width: calc(100% + 30px);
      height: 1px;
      content: "";
      border-bottom: 1px solid rgb(247 248 248 / 10%);
    }
  }

  ${HeaderCTA} {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
  }
`

const HeaderContentContainer = styled.div<IHeaderProps>`
  display: none;

  ${(props) =>
    props.isMenuOpen &&
    css`
      ${HeaderContentContainerMobileStyle}
    `}

  ${up("md")} {
    display: flex;
    flex: 1 0 auto;
  }
`

const StyledCustomBackground = styled(CustomBackground)<IHeaderProps>`
  ${(props) =>
    !props.isMenuOpen &&
    css`
      display: none;
    `}
`

const HeaderBlurList: Array<blurProps> = [
  {
    blur: "50px",
    color: colorVars.teal.p11,
    opacity: 0.8,
    pos: {
      bottom: "initial",
      left: "8%",
      right: "initial",
      top: "50%",
    },
    size: "45px",
  },
]

const HeaderContent = (props: IHeaderProps) => {
  const { cta, displayAnchors, displayCta, isMenuOpen, navItems } = props

  return (
    <>
      <HeaderContentContainer isMenuOpen={isMenuOpen}>
        <StyledCustomBackground
          blurs={HeaderBlurList}
          isMenuOpen={isMenuOpen}
        />
        {displayAnchors && navItems && isNotEmpty(navItems) ? (
          <HeaderNav>
            {navItems.map((navItem: any, index: number) => (
              <HeaderNavItem key={`header-nav-${index}`}>
                <HeaderNavItemAnchor
                  anchor={navItem.anchor}
                  title={navItem.anchorTitle}
                  type="anchor"
                />
              </HeaderNavItem>
            ))}
          </HeaderNav>
        ) : (
          <Spacer height={{ default: "32px" }} />
        )}
        {cta && isNotEmpty(cta) && displayCta && (
          <HeaderCTA link={cta} type="url" />
        )}
      </HeaderContentContainer>
    </>
  )
}

export const Header: FC<IHeaderProps> = (props) => {
  const headerData = props?.data?.wp?.siteGeneralSettings?.SiteSettings?.header
  const logoData = props?.data?.wp?.siteGeneralSettings?.SiteSettings?.siteLogo

  const { displayCta } = props
  const { isFloating, isMenuOpen, toggleMenuOpen } = useContext(UiContext)

  return (
    <HeaderContainer isFloating={isFloating} isMenuOpen={isMenuOpen}>
      <HeaderWrapper>
        <HeaderLogoLink to={process.env.GATSBY_URL ?? "/"}>
          <HeaderLogoImg alt="Airkit.ai" image={logoData} />
        </HeaderLogoLink>
        {displayCta && (
          <>
            <HeaderContent
              {...headerData}
              displayAnchors={props.displayAnchors}
              displayCta={displayCta}
              isMenuOpen={isMenuOpen}
            />
            <HeaderContentToggler
              className="header-content-toggler"
              onClick={toggleMenuOpen}>
              {isMenuOpen ? <MenuMobileClose /> : <MenuMobileOpen />}
            </HeaderContentToggler>
          </>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  )
}

const HeaderSection = (props: any) => {
  const { displayAnchors, displayCta } = props

  return (
    <StaticQuery
      query={graphql`
        query {
          wp {
            siteGeneralSettings {
              SiteSettings {
                siteLogo {
                  altText
                  title
                  gatsbyImage(quality: 100, placeholder: NONE, width: 240)
                }
                header {
                  navItems {
                    anchorTitle
                    anchor
                  }
                  cta {
                    target
                    title
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Header
          data={data}
          displayAnchors={displayAnchors}
          displayCta={displayCta}
        />
      )}
    />
  )
}

export default HeaderSection

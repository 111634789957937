import colorVars from "@/assets/styles/colorVars"
import { CustomBackground, blurProps } from "@/components/bg/blur-bg"
import { CopyrightBar } from "@/components/footer/copyright-bar"
import { FooterInfoBlock } from "@/components/footer/info-block"
import { Spacer } from "@/components/spacer"
import { flatListToHierarchical, useWindowSize } from "@/utilities/helpers"
import { StaticQuery, graphql } from "gatsby"
import React, { FC } from "react"
import { up } from "styled-breakpoints"
import styled from "styled-components"

export interface IFooterMenu {
  copyrightBar?: any
  copyrightTitle?: string
}

export interface IFooterProps {
  data?: any
}

const FooterContainer = styled.footer<IFooterProps>`
  position: relative;
  height: auto;
  padding: 0 16px;

  ${up("lg")} {
    max-width: calc(1120px + 32px);
    margin: 0 auto;
  }
`

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const FooterCustomBackground = styled(CustomBackground)`
  bottom: 0;
  display: block;
  max-width: 100vw;
`

const footerBlurList: Array<blurProps> = [
  {
    blur: "200px",
    color: colorVars.teal.main,
    opacity: 0.3,
    pos: {
      bottom: "initial",
      left: "-50%",
      right: "initial",
      top: "-85%",
    },
    size: "150px",
  },
  {
    blur: "100px",
    color: colorVars.blue.p11,
    opacity: 0.15,
    pos: {
      bottom: "initial",
      left: "-15%",
      right: "initial",
      top: "-25%",
    },
    size: "150px",
  },
  {
    blur: "135px",
    color: colorVars.pink.p11,
    opacity: 0.3,
    pos: {
      bottom: "initial",
      left: "initial",
      right: "-35%",
      top: "-60%",
    },
    size: "250px",
  },
  {
    blur: "110px",
    color: colorVars.blue.p11,
    opacity: 0.6,
    pos: {
      bottom: "initial",
      left: "initial",
      right: "-15%",
      top: "15%",
    },
    size: "80px",
  },
]

const footerBlurListSmall: Array<blurProps> = [
  {
    blur: "100px",
    color: colorVars.teal.main,
    opacity: 0.3,
    pos: {
      bottom: "initial",
      left: "-10%",
      right: "initial",
      top: "-85%",
    },
    size: "75px",
  },
  {
    blur: "50px",
    color: colorVars.blue.p11,
    opacity: 0.15,
    pos: {
      bottom: "initial",
      left: "0%",
      right: "initial",
      top: "-25%",
    },
    size: "75px",
  },
  {
    blur: "70px",
    color: colorVars.pink.p11,
    opacity: 0.3,
    pos: {
      bottom: "initial",
      left: "initial",
      right: "5%",
      top: "-60%",
    },
    size: "125px",
  },
  {
    blur: "55px",
    color: colorVars.blue.p11,
    opacity: 0.6,
    pos: {
      bottom: "initial",
      left: "initial",
      right: "10%",
      top: "10%",
    },
    size: "40px",
  },
]

export const Footer: FC<IFooterProps> = (props) => {
  const footerData = props?.data?.wp?.siteGeneralSettings?.SiteSettings?.footer
  const logoData = props?.data?.wp?.siteGeneralSettings?.SiteSettings?.siteLogo

  const { copyrightNotice, footerLinks, missionStatement } = footerData

  const copyrightItems = footerLinks || []
  copyrightItems.sort((a: any, b: any) => b.order - a.order)

  const organizedCopyright = flatListToHierarchical(copyrightItems)

  const copyrightBarData: any = {
    copyrightTitle: copyrightNotice ?? "Copyright Airkit.ai",
    items: organizedCopyright,
  }

  const { width: windowWidth } = useWindowSize()
  const isSmallViewport = windowWidth < 768

  return (
    <FooterContainer>
      <FooterCustomBackground
        blurs={isSmallViewport ? footerBlurListSmall : footerBlurList}
      />
      <Spacer height={{ default: "64px" }} />
      <InfoWrapper>
        <FooterInfoBlock logo={logoData} missionStatement={missionStatement} />
      </InfoWrapper>
      <Spacer height={{ default: "24px", md: "48px" }} />
      <CopyrightBar data={copyrightBarData} />
      <Spacer height={{ default: "64px" }} />
    </FooterContainer>
  )
}

const FooterSection = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wp {
            siteGeneralSettings {
              SiteSettings {
                siteLogo {
                  altText
                  title
                  gatsbyImage(quality: 100, placeholder: NONE, width: 240)
                }
                footer {
                  missionStatement
                  copyrightNotice
                  footerLinks {
                    link {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Footer data={data} />}
    />
  )
}

export default FooterSection

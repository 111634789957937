import { up } from "styled-breakpoints"
import styled from "styled-components"

export type SpacerProps = {
  bgColor?: string
  className?: string
  height?: {
    default?: string
    lg?: string
    md?: string
    xl?: string
    xxl?: string
  }
}
export const Spacer = styled.span<SpacerProps>`
  position: relative;
  display: block;
  /* stylelint-disable-next-line */
  height: ${(props) =>
    props.height && props.height.default && props.height.default};
  ${up("md")} {
    /* stylelint-disable-next-line */
    height: ${(props) => props.height && props.height.md && props.height.md};
  }
  ${up("lg")} {
    /* stylelint-disable-next-line */
    height: ${(props) => props.height && props.height.lg && props.height.lg};
  }

  ${up("xl")} {
    /* stylelint-disable-next-line */
    height: ${(props) => props.height && props.height.xl && props.height.xl};
  }

  ${up("xxl")} {
    /* stylelint-disable-next-line */
    height: ${(props) => props.height && props.height.xxl && props.height.xxl};
  }

  min-height: 0 !important;
  margin: 0 !important;
  pointer-events: none;
  background-color: ${(props) =>
    props.bgColor && props.bgColor !== ""
      ? `${props.bgColor} !important`
      : "transparent !important"};
`

export const NegativeSpacer = styled.span<SpacerProps>`
  position: relative;
  display: block;
  /* stylelint-disable-next-line */
  margin-bottom: ${(props) =>
    props.height && props.height.default && "-" + props.height.default};
  ${up("md")} {
    /* stylelint-disable-next-line */
    margin-bottom: ${(props) =>
      props.height && props.height.md && "-" + props.height.md};
  }
  ${up("lg")} {
    /* stylelint-disable-next-line */
    margin-bottom: ${(props) =>
      props.height && props.height.lg && "-" + props.height.lg};
  }
  ${up("xxl")} {
    /* stylelint-disable-next-line */
    margin-bottom: ${(props) =>
      props.height && props.height.xxl && "-" + props.height.xxl};
  }
`

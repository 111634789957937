const colorVars = {
  animations: {
    boxShadow1: "rgba(124, 107, 224, 0.1)",
    boxShadow2: "rgba(255, 255, 255, 0.1)",
    darkBorder: "#12122a",
    highlightGradientFrom: "rgba(255, 39, 104, 0)",
    highlightGradientTo: "rgba(255, 78, 131, 1)",
    liveStatus: "#2dd68f",
    messageBg1: "#1B1D2C",
    messageBg2: "#121228",
    messageBubble: "#1b1d2d",
    messagesViewBg: "#060816",
    platinum: "#f4f4f4",
    transparentGrey1: "rgba(244, 244, 244, 0.1)",
    transparentWhite1: "rgba(255, 255, 255, 0.5)",
    transparentWhite2: "rgba(255, 255, 255, 0.15)",
    transparentWhite3: "rgba(255, 255, 255, 0.2)",
    userHighlight: "#6257B2",
  },
  background: {
    dark: "rgb(2 4 22 / 100%)",
    white05: "rgba(255, 255, 255, 0.05)",
    white1: "rgba(255, 255, 255, 0.10)",
  },
  blue: {
    buttonHover: "#185CD4",
    main: "#126DE7",
    p0: "#FEFFFF",
    p1: "#E8F1FD",
    p2: "#D0E2FA",
    p3: "#B8D4F8",
    p4: "#A0C4F6",
    p5: "#88B5F2",
    p6: "#71A7F1",
    p7: "#5A99EF",
    p8: "#418AEC",
    p9: "#2A7CEA",
    p11: "#005EDC",
    stroke50: "rgba(139, 199, 255, 0.50)",
    textGradient: "linear-gradient(94deg, #88B6F3 0%, #5A99EF 100%)",
  },
  gray: {
    p3: "#2E3545",
  },
  lightPink: {
    main: "#FFA5CA",
    p0: "#FFFAFC",
    p1: "#FFF6FA",
    p2: "#FFEDF4",
    p3: "#FFE4F0",
    p4: "#FFDBEA",
    p5: "#FFD1E4",
    p6: "#FFC9DF",
    p7: "#FFC0DA",
    p8: "#FFB7D4",
    p9: "#FFAED0",
    p11: "#FF90BE",
  },
  navy: {
    main: "#001845",
    p0: "#F2F3F5",
    p1: "#E6E8ED",
    p2: "#CCD1DA",
    p3: "#B3BAC8",
    p4: "#99A3B5",
    p5: "#7F8BA1",
    p6: "#66748F",
    p7: "#4D5E7D",
    p8: "#33466A",
    p9: "#1A3058",
    p11: "#001130",
  },
  orange: {
    main: "#FF6C00",
    p0: "#FFF7F2",
    p1: "#FFF1E6",
    p2: "#FFE2CC",
    p3: "#FFD3B3",
    p4: "#FFC499",
    p5: "#FFB57F",
    p6: "#FFA766",
    p7: "#FF994D",
    p8: "#FF8933",
    p9: "#FF7B1A",
    p11: "#FF6005",
    stroke50: "rgba(255, 204, 128, 0.50)",
    textGradient: "linear-gradient(94deg, #FFA766 0%, #FF8933 100%)",
  },
  pink: {
    main: "#FF4E83",
    p0: "#FFF6F9",
    p1: "#FFEEF3",
    p2: "#FFDCE6",
    p3: "#FFCADA",
    p4: "#FFB8CD",
    p5: "#FFA6C0",
    p6: "#FF95B5",
    p7: "#FF84A9",
    p8: "#FF719C",
    p9: "#FF6090",
    p11: "#FF4371",
    stroke50: "rgba(255, 133, 167, 0.50)",
    textGradient: "linear-gradient(94deg, #FF95B5 0%, #FF719C 100%)",
  },
  states: {
    danger: "rgb(229, 115, 115)",
  },
  teal: {
    main: "#29D7D7",
    p0: "#F4FDFD",
    p1: "#EAFBFB",
    p2: "#D4F7F7",
    p3: "#BFF3F3",
    p4: "#A9EFEF",
    p5: "#93EAEA",
    p6: "#7FE7E7",
    p7: "#6AE3E3",
    p8: "#54DFDF",
    p9: "#3FDBDB",
    p11: "#2CCECE",
    stroke50: "rgba(117, 227, 225, 0.50)",
    textGradient: "linear-gradient(94deg, #7FE7E7 0%, #3FDBDB 100%)",
  },
  text: {
    gray: "#B4BCD0",
    neutral: "#D2D3E0",
    neutral800: "#A5ACBA",
    whiteGradient: "linear-gradient(180deg, #FFF 33.85%, #D7D7D7 100%)",
  },
  violet: {
    main: "#7C6BE0",
    p0: "#F8F7FD",
    p1: "#F2F1FC",
    p2: "#E5E1F9",
    p3: "#D8D3F6",
    p4: "#CBC4F3",
    p5: "#BDB4EF",
    p6: "#B0A6EC",
    p7: "#9D91E8",
    p8: "#9689E6",
    p9: "#8A7AE4",
    p11: "#6B57E1",
    stroke50: "rgba(164, 150, 232, 0.50)",
    textGradient: "linear-gradient(94deg, #B0A6EC 0%, #9689E6 100%)",
  },
  yellow: {
    main: "#FFCF21",
    p0: "#FFFCF4",
    p1: "#FFFBE9",
    p2: "#FFF5D3",
    p3: "#FFF1BD",
    p4: "#FFECA6",
    p5: "#FFE68F",
    p6: "#FFE27A",
    p7: "#FFDE64",
    p8: "#FFD84D",
    p9: "#FFD438",
    p11: "#FFC800",
    stroke50: "rgba(255, 245, 157, 0.50)",
    textGradient: "linear-gradient(94deg, #FFE27A 0%, #FFD94D 100%)",
  },
}

export default colorVars

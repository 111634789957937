import colorVars from "@/assets/styles/colorVars"
import PrivacyOptionsIcon from "@/assets/svg/ui/privacy_options.inline.svg"
import React, { FC } from "react"
import { up } from "styled-breakpoints"
import styled from "styled-components"

interface ICopyrightLink {
  link?: Queries.WpAcfLink
}

export interface ICopyright {
  copyrightTitle?: string
  items: Array<ICopyrightLink>
}

export interface CopyrightBarProps {
  className?: string
  data?: ICopyright
}

const Link = styled.a`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "calt" off;
  line-height: 24px;
  color: ${colorVars.text.neutral800};
  text-align: center;
  letter-spacing: -0.14px;
  transition: color 200ms ease;

  &:hover,
  &:active,
  &:focus {
    color: white;
  }
`

const Container = styled.div`
  display: block;
  max-width: 1320px;
  margin: 0 auto;
`

const Wrapper = styled.div<CopyrightBarProps>`
  display: flex;
  flex-flow: column-reverse;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  border-top: 1px solid ${colorVars.gray.p3};

  ${up("md")} {
    flex-flow: row-reverse wrap;
    align-items: flex-end;
    justify-content: space-between;
  }
`

const CopyrightTitle = styled.span<CopyrightBarProps>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "calt" off;
  line-height: 24px;
  color: ${colorVars.text.neutral800};
  text-align: center;
  letter-spacing: -0.14px;

  p {
    margin: 0;
  }
`

const CopyrightTitleLink = styled.a``

const DataPrivacy = styled.div``

const DataPrivacyLink = styled.a`
  display: flex;
  align-items: center;
  color: ${colorVars.text.neutral800};
  letter-spacing: -0.14px;
  transition: color 200ms ease;

  &:hover,
  &:active,
  &:focus {
    color: white;
  }

  > svg {
    height: 17px;
    margin-left: 6px;
  }
`

const CopyrightLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px 32px;
  margin-top: 23px;

  ${up("md")} {
    margin-top: 30px;
  }
`

export const CopyrightBar: FC<CopyrightBarProps> = (props) => {
  const { data } = props

  return (
    <Container>
      <Wrapper>
        <CopyrightTitle>
          <CopyrightTitleLink href="https://www.salesforce.com">
            &copy; {new Date().getFullYear()} Airkit.ai from Salesforce
          </CopyrightTitleLink>
        </CopyrightTitle>
        <DataPrivacy>
          <DataPrivacyLink href="https://www.salesforce.com/form/other/privacy-request">
            Your Privacy Choices{" "}
            <PrivacyOptionsIcon aria-label="California Consumer Privacy Act (CCPA) Opt-Out Icon" />
          </DataPrivacyLink>
        </DataPrivacy>
        {data?.items && data?.items.length >= 1 && (
          <CopyrightLinks>
            {data?.items.map((item, index) => (
              <Link
                href={item?.link?.url ?? "#"}
                key={`copyright-link-${index}`}
                target={item?.link?.target ?? "_self"}>
                {item?.link?.title}
              </Link>
            ))}
          </CopyrightLinks>
        )}
      </Wrapper>
    </Container>
  )
}

import colorVars from "@/assets/styles/colorVars"
import AirkitAiLogo from "@/assets/svg/ui/ic_airkit_ai_logo.inline.svg"
import React, { FC } from "react"
import { up } from "styled-breakpoints"
import styled from "styled-components"

import { BaseImage } from "../image/base-image"

export interface FooterInfoBlockProps {
  className?: string
  logo?: Queries.WpMediaItem
  missionStatement?: string
}

const Wrapper = styled.div<FooterInfoBlockProps>``

const FooterDescription = styled.p<FooterInfoBlockProps>`
  max-width: 410px;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "calt" off;
  line-height: 24px;
  color: ${colorVars.text.neutral800};
  letter-spacing: -0.09px;

  ${up("lg")} {
    max-width: 390px;
  }
`

const SiteIconWrapper = styled.div`
  position: relative;
  margin-bottom: 23px;
`

const SiteIcon = styled(BaseImage)`
  position: relative;
  width: 120px;
  height: auto;
`

const defaultMissionStatement =
  "With Airkit.ai for eCommerce, brands achieve higher CSAT scores, improve operational efficiency, and create new revenue opportunities."

export const FooterInfoBlock: FC<FooterInfoBlockProps> = (props) => {
  const { className, logo, missionStatement } = props

  return (
    <Wrapper className={className ?? ""}>
      <SiteIconWrapper>
        {logo ? <SiteIcon image={logo} /> : <AirkitAiLogo />}
      </SiteIconWrapper>
      <FooterDescription>
        {missionStatement ?? defaultMissionStatement}
      </FooterDescription>
    </Wrapper>
  )
}

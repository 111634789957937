import { getImageFromMediaItem } from "@/utilities/helpers"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { FC } from "react"
import styled from "styled-components"

export interface IconProps {
  // When present this will subscribe the image alt text that comes from the back-office
  alt?: string
  className?: string
  image?: Queries.WpMediaItem
  isPrint?: boolean
  loading?: "eager" | "lazy"
  title?: string
}

const Img = styled.img`
  width: 100%;
`

export const BaseImage: FC<IconProps> = (props) => {
  const { alt, className, image, loading, title } = props

  if (!image) {
    return null
  }

  const imgTitle = title ?? image.title ?? ""
  const altText = alt ?? image.altText ?? ""
  const imgAlt = altText !== "" ? altText : imgTitle

  // DON'T FORGET to add mimeType field to graphQL queries that may have svg's
  const isSVG = image.mimeType && image.mimeType === "image/svg+xml"

  const imageSrc =
    image?.localFile?.publicURL ||
    image?.link ||
    image?.mediaItemUrl ||
    image?.sourceUrl ||
    ""

  let imageObj: any = image

  if (!image.gatsbyImage) {
    imageObj = getImageFromMediaItem(image) as IGatsbyImageData
  } else {
    imageObj = image.gatsbyImage
  }

  // when the image is an SVG use a simple img tag to display it
  if (isSVG || (!imageObj && imageSrc !== "")) {
    return (
      <div className={className}>
        <Img alt={imgAlt} loading="lazy" src={imageSrc} />
      </div>
    )
  }

  if (!imageObj) {
    return null
  } else {
    return (
      <>
        {imageObj && !props.isPrint ? (
          <GatsbyImage
            alt={imgAlt}
            className={className}
            image={imageObj}
            loading={loading}
          />
        ) : (
          <div className={className}>
            <Img alt={imgAlt} loading="lazy" src={imageSrc} />
          </div>
        )}
      </>
    )
  }
}

module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.7/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.13.4_gatsby-plugin-image@3.13.1_gatsby-plugin-sharp@5.13.1_gatsby-t_6ssdfxjmu4wy75xvch3av2gsmy/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://live-airkit-ai.pantheonsite.io/core/graphql","develop":{"hardCacheMediaFiles":false,"hardCacheData":false,"nodeUpdateInterval":5000},"debug":{"disableCompatibilityCheck":true,"preview":false,"timeBuildSteps":false,"throwRefetchErrors":false},"schema":{"perPage":20,"timeout":120000,"requestConcurrency":50,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"localFile":{"excludeByMimeTypes":["image/jpeg","image/png"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_gatsby@5.13.7_rea_7boaoychi6ib3fwqqdrwpeujku/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/images/favicon_pink.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1ba3b3ce3b1e02bb2122a9c64f098312"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MGZGV73","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.13.1_gatsby@5.13.7_webpack@5.93.0/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/fonts/*":["Cache-Control: public","Cache-Control: max-age=365000000","Cache-Control: immutable"],"/images/*":["Cache-Control: public","Cache-Control: max-age=604800"]},"allPageHeaders":["X-Frame-Options: ALLOW-FROM https://app.ruist.com/ https://m.airkitdemos.com/ https://runway.airkit.com","Strict-Transport-Security: max-age=63072000; includeSubDomains; preload","Access-Control-Allow-Origin: *","X-Content-Type-Options: nosniff","Content-Security-Policy: default-src *.crazyegg.com 'self';  frame-ancestors 'self';  script-src https://www.gstatic.com/ https://www.google-analytics.com https://optimize.google.com https://assets.calendly.com *.crazyegg.com 'report-sample' 'unsafe-eval' 'unsafe-inline' 'self' *;  style-src https://fonts.googleapis.com *.crazyegg.com 'report-sample' 'unsafe-inline' 'self' *;  object-src 'none';  base-uri 'self';  connect-src *.crazyegg.com 'self' *;  font-src 'self' cloudfront.net *.cloudfront.net *.pantheonsite.io *.airkit.com *.airkit.ai fonts.gstatic.com data:;  frame-src 'self' https://calendly.com *.calendly.com www.google.com www.youtube.com *.crazyegg.com *.airkitapps.com *.airkitdemos.com *.airkit.com *.airkit.ai;  img-src https://www.gstatic.com *.crazyegg.com * data: https://www.google-analytics.com blob:;  manifest-src 'self';  worker-src 'self' blob:;  media-src 'self' *.pantheonsite.io *.airkit.com *.airkit.ai;"],"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.5.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import colorVars from "@/assets/styles/colorVars"
import { up } from "styled-breakpoints"
import { createGlobalStyle, css } from "styled-components"

import { styleVars } from "./styleVars"

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -webkit-print-styles: exact;
  }
  /* stylelint-disable-next-line no-duplicate-selectors */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  img,
  svg {
    vertical-align: top;
  }

  /* disable img transitions for Loki Visual Regression testing */
  *[loki-test] {
    img {
      opacity: 1 !important;
      transition: none !important;
    }

    video {
      display: none !important;
    }
  }

  html {
    font-size: 62.5%;
    font-variant-ligatures: none;
    text-shadow: rgb(0 0 0 / 1%) 0 0 0.1rem;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html.scroll-lock {
    overflow: hidden;
  }

  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 54px;

    ${up("md")} {
      padding-top: 66px;
    }

    /* CUSTOM LG BREAKPOINT FROM HEADER */
    @media (min-width: 1099px) {
      padding-top: 82px;
    }
  }

  .air-client-launch-img {
    cursor: pointer;

    :hover {
      filter: brightness(.95);
      opacity: 1 !important;
    }
  }


  .isPrint {
    header, footer {
      display: none;
    }

    main {
      padding: 0;
    }
  }

  body {
    width: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: overlay;
    font-size: ${styleVars.baseFontSize}px;
    font-weight: 400;
    line-height: 1.5;
    color: ${styleVars.colors.textMain};
    /* stylelint-disable-next-line */
    font-family: 'Inter', system-ui, helvetica, sans-serif;
    background-color: ${colorVars.background.dark};
  }

  body.header-no-shadow header {
    box-shadow: none !important;
  }

  body.modal-open header {
    padding-right: 0;
  }

  body main.page-home {
    padding-top: 0;

    ${up("lg")} {
      padding-top: 0;
    }
  }

  body main.hasTopBanner {
    padding-top: 0;

    ${up("lg")} {
      padding-top: 38px;
    }
  }

  body .overflow-hidden {
    overflow: hidden;
  }

  a,
  a * {
    font-family: Inter, sans-serif;
    color: ${styleVars.colors.pink};
    text-decoration: none;
  }

  button {
    font-family: Inter, sans-serif;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  *:focus {
    touch-action: manipulation;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  @media print {
    header, footer, .air-client-launch-container {
      display: none !important;
    }    
  }

  /* FIX FOR REACT-RESPONSIVE-MODAL CLOSE SCREEN GLITCH:
   * https://github.com/pradel/react-responsive-modal/issues/495
   */
  .react-responsive-modal-overlay,
  .react-responsive-modal-container,
  .react-responsive-modal-modal {
    animation-fill-mode: forwards !important;
  }

  pre {
    color: black;
  }
`

export const GenericContentStyle = css`
  p,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span {
    color: ${colorVars.text.neutral};
  }

  h1,
  h2,
  h3 {
    margin: 40px 0 12px;
    ${up("md")} {
      margin: 60px 0 12px;
    }
  }

  ul,
  ol {
    margin: 8px 0;
    ${up("md")} {
      margin: 12px 0;
    }
    li {
      padding: 3px 0;
      ${up("md")} {
        padding: 6px 0;
      }
    }
  }

  a {
    color: ${colorVars.blue.p4};
    transition: color 300ms ease;

    &:hover {
      color: ${colorVars.blue.p3};
    }
  }
`

import { only, up } from "styled-breakpoints"
import { css, keyframes } from "styled-components"

import hexRgb from "../../utilities/hex-to-rgb"

export type ButtonColor =
  | "black"
  | "blue"
  | "darkBlue"
  | "darken"
  | "fadedPink"
  | "green"
  | "grey"
  | "lightGrey"
  | "pink"
  | "purple"
  | "sage-green"
  | "whiteBlue"
  | "whiteDarkBlue"

export const styleVars: any = {
  animations: {
    flipInX: keyframes`
      from {
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
      }
    
      40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
      }
    
      60% {
        opacity: 1;
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      }
    
      80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      }
    
      to {
        transform: perspective(400px);
      }
    `,
    flipOutX: keyframes`
      from {
        transform: perspective(400px);
      }

      30% {
        opacity: 1;
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      }

      to {
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      }
    `,
    rotation: keyframes`
     from {
          transform: rotate(360deg);
     }
     to {
          transform: rotate(0deg);
     }
    `,
    underLineAnimation: css`
      /* stylelint-disable-next-line */
      background: linear-gradient(currentColor, currentColor) bottom / 0 0.1em
        no-repeat;
      transition: 0.5s background-size;

      :hover {
        background-size: 100% 0.1em;
      }
    `,
  },
  baseFontSize: 16,
  colors: {
    actionBlue: "#659af6",
    anotherBlue: "#bfe8e8",
    bgBluishGrey: "#66748f",
    bgCodeTab: "#2f2f42",
    bgDarkBlue: "#001845",
    bgDarkBlueLight: "#0d244e",
    bgDarkBlueLightRGB: hexRgb("#0d244e", { format: "array" })
      .slice(0, 3)
      .toString(),
    bgDarkBlueRGB: hexRgb("#001845", { format: "array" })
      .slice(0, 3)
      .toString(),
    bgDarkGreyBlue: "#111626",
    bgDarkerBlue: "#021333",
    bgGrey: "#f2f3f6",
    bgGreyCard: "#27272a",
    bgGreyPill: "#e8e8e8",
    bgGreyQuote: "#1b1c1d",
    bgGreyRGB: hexRgb("#f2f3f6", { format: "array" }).slice(0, 3).toString(),
    bgGreyish: "#cdeff1",
    bgLight: "#FFFFFF",
    bgLightBlue: "#F3FCFF",
    bgLightBlueRGB: hexRgb("#F3FCFF", { format: "array" })
      .slice(0, 3)
      .toString(),
    bgLightGrey: "#f9f9fb",
    bgLightRGB: hexRgb("#FFFFFF", { format: "array" }).slice(0, 3).toString(),
    bgMediumBlue: "#1a2f58",
    bgMediumLightBlue: "#33466a",
    blue100: "#ECF4FF",
    blue200: "#D0E2FA",
    blurBlue: "#67d4d5",
    borderBlue: "#84aef8",
    borderGreyBlue: "#3b3b44",
    callOutBlueBG: "#eff4fe",
    callOutBlueBorder: "#e0eafd",
    callOutGreenBG: "#effafa",
    callOutGreenBorder: "#e0f6f6",
    callOutPinkBG: "#fff8fa",
    callOutPinkBorder: "#ffeaf0",
    dark666: "#060606",
    darkBlack: "#0b0b0c",
    darkBlackRGB: hexRgb("#0b0b0c", { format: "array" }).slice(0, 3).toString(),
    darkBlue: "#1D69CF",
    darkBlueRGB: hexRgb("#1D69CF", { format: "array" }).slice(0, 3).toString(),
    darkCharcoal: "#333",
    darkCharcoalRGB: hexRgb("#333", { format: "array" }).slice(0, 3).toString(),
    darkGraphPink: "#863968",
    darkPink: "#E84A79",
    darkPinkRGB: hexRgb("#E84A79", { format: "array" }).slice(0, 3).toString(),
    darkPurple: "#4a39b0",
    darkRock: "#17171b",
    darkSageGreen: "#00514c",
    darkSeaGreen: "#51b1b2",
    darkSeaGreenRGB: hexRgb("#51b1b2", { format: "array" })
      .slice(0, 3)
      .toString(),
    darkerBlurGreen: "#1e4444",
    darkerBlurPink: "#5b1637",
    fadedPink: "#ffeaf0",
    fadedPinkRGB: hexRgb("#ffeaf0", { format: "array" }).slice(0, 3).toString(),
    focusPink: "#ffd4e1",
    grayBlue: "#bed4ff",
    grayishEighth: "#f1f5f9",
    grayishFifth: "#cdd0d4",
    grayishFourth: "#ccd1da",
    grayishMain: "#f7f8f9",
    grayishSecondary: "#e8edf3",
    grayishSeventh: "#F8FAFC",
    grayishSixth: "#adb5bd",
    grayishSixthRGB: hexRgb("#adb5bd", { format: "array" })
      .slice(0, 3)
      .toString(),
    grayishThird: "#e6e8ec",
    greyBlue: "#4d5d7d",
    greyBorder: "#d8d8d8",
    lightBlue: "#218EF5",
    lightDarkBlueGraph: "#324464",
    lightGraphPink: "#ffdce6",
    lightGray: "#fcfcfd",
    lightPink: "#FD85A9",
    lightPink2: "#ff78a1",
    lightPink3: "#ff95b5",
    lightPink4: "#ff6895",
    lightPinkRGB: hexRgb("#FD85A9", { format: "array" }).slice(0, 3).toString(),
    lightPurple: "#f8f6ff",
    lightSageGreen: "#f9f9fb",
    lighterBlue: "#f6fbff",
    lighterGreen: "#f0fbfb",
    lighterPink: "#ffeff4",
    lighterPurple: "#f8f6ff",
    midBlue: "#1F7CE1",
    pink: "#FF4E83",
    pink2: "#f95285",
    pink2RGB: hexRgb("#f95285", { format: "array" }).slice(0, 3).toString(),
    pinkRGB: hexRgb("#FF4E83", { format: "array" }).slice(0, 3).toString(),
    purple: "#7c6be1",
    purpleRGB: hexRgb("#7c6be1", { format: "array" }).slice(0, 3).toString(),
    richSeaGreen: "#2DD4D4",
    sageGreen: "#00847f",
    sageGreenRGB: hexRgb("#00847f", { format: "array" }).slice(0, 3).toString(),
    seaGreen: "#67D4D5",
    seaGreenRGB: hexRgb("#67D4D5", { format: "array" }).slice(0, 3).toString(),
    skyBlue: "#659AF6",
    skyBlueExtraLight: "#f6fbff",
    skyBlueLight: "#b2ccfa",
    skyBlueRGB: hexRgb("#659AF6", { format: "array" }).slice(0, 3).toString(),
    snowWhite: "#e0ebfd",
    snowWhiteRGB: hexRgb("#e0ebfd", { format: "array" }).slice(0, 3).toString(),
    tableCellGrey: "#eee",
    textAnotherGray: "#b3bac7",
    textBlue: "#1d69cf",
    textBluishGrey: "#99a3b5",
    textCard: "#767f8b",
    textDarkGray: "#767F8B",
    textDarkGrayRGB: hexRgb("#767F8B", { format: "array" })
      .slice(0, 3)
      .toString(),
    textError: "#FF3434",
    textErrorRGB: hexRgb("#FF3434", { format: "array" }).slice(0, 3).toString(),
    textFeatureCardDescription: "#a7a9be",
    textFooterGrey: "#808ba2",
    textLightGray: "#CDD0D4",
    textLightGrayRGB: hexRgb("#CDD0D4", { format: "array" })
      .slice(0, 3)
      .toString(),
    textMain: "#1A293E",
    textMainRGB: hexRgb("#1A293E", { format: "array" }).slice(0, 3).toString(),
    textMidGray: "#A3A9B2",
    textMidGrayRGB: hexRgb("#A3A9B2", { format: "array" })
      .slice(0, 3)
      .toString(),
    textVeryLightGray: "#E4EEF3",
  },
  colorsRGB: {
    actionBlue: "101, 154, 246",
    actionBlueBlur: "29, 105, 207",
    blue100: "236, 244, 255",
    blue200: "208, 226, 250",
    blueish: "37, 53, 95",
    darkGray: "26, 41, 62",
    darkPink: "232, 74, 121",
    focusPink: "255, 212, 225",
    gray: "167, 169, 179",
    pink: "255, 78, 131",
  },
  font: "''Inter', 'Helvetica Neue', system-ui, helvetica, sans-serif",
  gradientBackgrounds: {
    blue: css`
      background-image: linear-gradient(#f3fcff, rgba(243, 252, 255, 0));
    `,
    blue2blue: css`
      background-image: linear-gradient(to right, #1d69cf -6%, #659af6 100%);
    `,
    blue2white: css`
      background-image: linear-gradient(180deg, #ecf4ff, rgb(236 244 255 / 0%));
    `,
    blueTop: css`
      background-image: linear-gradient(
        to top,
        #f3fcff,
        rgba(243, 252, 255, 0)
      );
    `,
    grey: css`
      background-image: linear-gradient(to bottom, #fff, #f2f3f6);
    `,
    greyish: css`
      background-image: linear-gradient(
        to bottom,
        rgba(28, 29, 31, 0) 0%,
        rgba(28, 29, 31, 0.26) 16%,
        rgb(28, 29, 31, 0.52) 50%,
        rgba(28, 29, 31, 0.26) 87%,
        rgba(28, 29, 31, 0) 100%
      );
    `,
    pink: css`
      background-image: linear-gradient(
        to top,
        #ffd9e4,
        rgba(255, 217, 228, 0),
        rgba(255, 217, 228, 0)
      );
    `,
    pink2pink: css`
      background-image: linear-gradient(to right, #e84a79 -6%, #ff4e83 100%);
    `,
    pinkTop: css`
      background-image: linear-gradient(
        to bottom,
        #ffd9e485,
        rgba(255, 217, 228, 0) 108%
      );
    `,
  },
  padding: {
    article: css`
      padding: 0 16px;
      ${up("md")} {
        padding: 0 20px;
      }
      ${up("lg")} {
        padding: 0 80px;
      }
      ${up("xl")} {
        padding: 0 176px;
      }
    `,
    section: css`
      padding: 0 16px;
      ${up("md")} {
        padding: 0 20px;
      }
      ${up("lg")} {
        padding: 0 80px;
      }
    `,
  },
  textStyles: {
    buildCaption: css`
      font-size: 15px;
      font-weight: 600;
      line-height: 1.6;

      ${up("md")} {
        font-size: 16px;
      }
    `,
    builderCardDescription: css`
      font-size: 18px;
      font-weight: normal;
      line-height: 1.6;

      ${up("md")} {
        font-size: 20px;
      }
    `,
    button: css`
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    checkBlockDescription: css`
      font-size: 16px;
      line-height: 1.6;

      ${up("md")} {
        font-size: 18px;
      }
    `,
    checkBlockTitle: css`
      font-size: 16px;
      font-weight: bold;
      line-height: 1.2;

      ${up("md")} {
        font-size: 18px;
      }
    `,
    faqDescription: css`
      font-size: 16px;
      line-height: 22px;

      ${up("md")} {
        font-size: 18px;
        line-height: 25px;
      }
    `,
    featureCardSubtitle: css`
      font-size: 10px;
      font-weight: 500;
      line-height: 1.45;

      ${up("md")} {
        font-size: 18px;
      }
    `,
    featureCardTitle: css`
      font-size: 32px;
      font-weight: bold;
      line-height: 1.26;

      ${up("md")} {
        font-size: 42px;
        line-height: 1.28;
        letter-spacing: -0.42px;
      }
    `,
    homeV3Description: css`
      font-size: 16px;
      font-weight: 500;
      line-height: 1.45;

      ${up("md")} {
        font-size: 20px;
      }
    `,
    homeV3DescriptionAlt: css`
      font-size: 16px;
      font-weight: 500;
      line-height: 1.62;

      ${up("md")} {
        font-size: 20px;
        line-height: 1.6;
      }
    `,
    /* Let's rethink this name afterwards */
    homeV3Title: css`
      font-size: 32px;
      font-weight: bold;
      line-height: 1.26;

      ${up("md")} {
        font-size: 42px;
        letter-spacing: -0.42px;
      }
    `,
    l: css`
      font-size: 18px;
      font-weight: normal;
      line-height: 24px;

      ${up("md")} {
        font-size: 22px;
        line-height: 32px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    l_with_l_mobile: css`
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      ${up("md")} {
        font-size: 20px;
        line-height: 32px;
      }
    `,
    l_with_m_mobile: css`
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      ${up("md")} {
        font-size: 18px;
        line-height: 32px;
      }
    `,
    lBold: css`
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;

      ${up("md")} {
        font-size: 22px;
        line-height: 32px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    link: css`
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    m: css`
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    mBold: css`
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    mSemiBold: css`
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    pill: css`
      font-size: 18px;
      font-weight: 600;
      line-height: 1.11;

      ${up("md")} {
        font-size: 20px;
        line-height: 1;
      }
    `,
    postTitle: css`
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    `,
    priceDescription: css`
      font-size: 16px;
      line-height: 22.4px;
    `,
    pricingCardSubtitle: css`
      font-size: 14px;
      line-height: 19.6px;
    `,
    s: css`
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    sBold: css`
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    statLabel: css`
      font-size: 11px;
      line-height: 15.4px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    `,
    statistics: css`
      font-size: 48px;
      font-weight: bold;
      line-height: 56px;
    `,
    statisticsContent: css`
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
    `,
    subTag: css`
      font-size: 13px;
      font-weight: normal;
      line-height: 20px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    subtitle: css`
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t0: css`
      font-size: 30px;
      font-weight: bold;
      line-height: 1.2;

      ${up("md")} {
        font-size: 56px;
        line-height: normal;
      }

      ${up("lg")} {
        font-size: 68px;
        line-height: normal;
      }
    `,
    t1: css`
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;

      ${up("md")} {
        font-size: 56px;
        line-height: 56px;
      }

      ${up("lg")} {
        font-size: 64px;
        line-height: 64px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t2: css`
      font-size: 32px;
      font-weight: bold;
      line-height: 36px;

      ${up("md")} {
        font-size: 44px;
        line-height: 52px;
      }
      ${up("lg")} {
        font-size: 48px;
        line-height: 56px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t2_fixed: css`
      font-size: 48px;
      font-weight: bold;
      line-height: 56px;
    `,
    t3: css`
      font-size: 28px;
      font-weight: bold;
      line-height: 36px;

      ${up("lg")} {
        font-size: 40px;
        line-height: 48px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t4: css`
      font-size: 22px;
      font-weight: bold;
      line-height: 28px;

      ${up("md")} {
        font-size: 28px;
        line-height: 40px;
      }

      ${up("lg")} {
        font-size: 32px;
        line-height: 40px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t5: css`
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;

      ${up("lg")} {
        font-size: 24px;
        line-height: 32px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t6: css`
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;

      ${up("md")} {
        font-size: 20px;
        line-height: 28px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t22: css`
      font-size: 20px;
      line-height: 28px;

      ${up("md")} {
        font-size: 32px;
        line-height: 40px;
      }
      ${up("lg")} {
        font-size: 48px;
        line-height: 56px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t22_alt: css`
      font-size: 32px;
      line-height: 1.13;
      ${only("md")} {
        margin: 0 auto;
        font-size: 44px;
        line-height: 1.18;
      }
      ${up("lg")} {
        font-size: 48px;
        line-height: 1.17;
      }
    `,
    t23: css`
      font-size: 32px;
      line-height: 40px;

      ${up("md")} {
        font-size: 48px;
        line-height: 56px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t24: css`
      font-size: 32px;
      line-height: 36px;

      ${up("md")} {
        font-size: 48px;
        line-height: 56px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t25: css`
      font-size: 32px;
      line-height: 40.3px;

      ${up("md")} {
        font-size: 52px;
        line-height: 56px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t26: css`
      font-size: 36px;
      line-height: 36px;

      ${up("md")} {
        font-size: 52px;
        line-height: 56px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t26_fixed: css`
      font-size: 40px;
      line-height: 48px;
    `,
    t32: css`
      font-size: 32px;
      font-weight: bold;
      line-height: 36px;

      ${up("md")} {
        font-size: 40px;
        line-height: 48px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t32_fixed: css`
      font-size: 32px;
      font-weight: bold;
      line-height: 36px;

      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t33: css`
      font-size: 28px;
      font-weight: bold;
      line-height: 36px;

      ${up("md")} {
        font-size: 32px;
        line-height: 36px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t34: css`
      font-size: 28px;
      font-weight: bold;
      line-height: 36px;

      ${up("md")} {
        font-size: 40px;
        line-height: 48px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t42: css`
      font-size: 22px;
      font-weight: bold;
      line-height: 28px;

      ${up("md")} {
        font-size: 24px;
        line-height: 32px;
      }

      ${up("lg")} {
        font-size: 32px;
        line-height: 40px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t43: css`
      font-size: 22px;
      font-weight: bold;
      line-height: 28px;

      ${up("md")} {
        font-size: 32px;
        line-height: 40px;
      }
    `,
    t52: css`
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;

      ${up("md")} {
        font-size: 24px;
        line-height: 32px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t52_fixed: css`
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    `,
    t53: css`
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;

      ${up("md")} {
        font-size: 24px;
        line-height: 28.8px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t62: css`
      font-size: 13px;
      font-weight: 600;
      line-height: 18.2px;

      ${up("xl")} {
        font-size: 20px;
        line-height: 28px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    t63_fixed: css`
      font-size: 18px;
      line-height: 28.8px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    tag: css`
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    tooltip: css`
      font-size: 11px;
      font-weight: 600;
      line-height: normal;
    `,
    xl: css`
      font-size: 20px;
      font-weight: normal;
      line-height: 28px;

      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    xl_with_l_mobile: css`
      font-size: 18px;
      line-height: 28.8px;

      ${up("md")} {
        font-size: 20px;
        line-height: 32px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    xl_with_m_mobile: css`
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;

      ${up("md")} {
        font-size: 20px;
        line-height: 28px;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    xlBold: css`
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;

      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    xlBold_with_l_mobile: css`
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;

      ${up("md")} {
        font-size: 20px;
        line-height: 1.5;
      }
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    xs: css`
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
    xxs: css`
      font-size: 10px;
      font-weight: normal;
      line-height: normal;
      /* stylelint-disable-next-line no-eol-whitespace */ /* stylelint-disable-next-line no-missing-end-of-source-newline */
    `,
  },
}

const swiperStyles = {
  arrowNav: css`
    .swiper-button-disabled {
      opacity: 0;
    }

    .swiper-button-next {
      right: 16px;
    }

    .swiper-button-prev {
      left: 16px;
    }

    .swiper-button-prev:not(.swiper-button-disabled),
    .swiper-button-next:not(.swiper-button-disabled) {
      z-index: 12;
      flex-grow: 0;
      width: 48px;
      height: 48px;
      margin-top: -42px;
      color: ${styleVars.colors.textMain};
      background-color: #fff;
      border: solid 1px ${styleVars.colors.grayishThird};

      border-radius: 200px;
      box-shadow: 0 4px 12px 0 rgba(0, 24, 69, 0.1),
        0 2px 2px 0 rgba(0, 24, 69, 0.04);
      opacity: 1;
      transition: opacity 0.36s ease-in-out;

      ::after {
        font-size: 18px;
      }
    }

    ${up("lg")} {
      && {
        .swiper-button-next {
          right: 24px;
          margin-top: -33px;
        }

        .swiper-button-prev {
          left: 24px;
          margin-top: -33px;
        }
      }
    }
  `,
  scrollbar: css`
    .swiper-scrollbar {
      position: relative;
      margin-top: 27px;
      background-color: rgba(205, 208, 212, 0.3);
      border-radius: 50px;

      ${up("lg")} {
        margin-top: 26px;
      }

      .swiper-scrollbar-drag {
        background-color: ${styleVars.colors.textLightGray};
        border-radius: 50px;

        :not(:last-of-type) {
          display: none;
        }
      }
    }
  `,
}

styleVars.swiper = {
  ...swiperStyles,
  arrowNavPost: css`
    ${swiperStyles.arrowNav}

    && {
      .swiper-button-next,
      .swiper-button-prev {
        margin-top: -64px;
      }

      ${up("lg")} {
        .swiper-button-next,
        .swiper-button-prev {
          margin-top: -42px;
        }
      }
    }
  `,
}

const BtnExplodeAnim = (color?: ButtonColor) => keyframes`
0% {
  width: 0;
  height: 0;
  margin-top: 0;
  margin-left: 0;
  background-color: rgba(${handleRGBColorType(color || "pink")} / 20%);
}

100% {
  width: 800px;
  height: 800px;
  margin-top: -400px;
  margin-left: -400px;
  background-color: rgba(${handleRGBColorType(color || "pink", true)} / 100%);
}`

const BtnDesplodeAnim = (color?: ButtonColor) => keyframes`
 0% {
    width: 800px;
    height: 800px;
    margin-top: -400px;
    margin-left: -400px;
    background-color: rgba(${handleRGBColorType(color || "pink", true)} / 100%);
  }

  100% {
    width: 0;
    height: 0;
    margin-top: 0;
    margin-left: 0;
    background-color: rgba(${handleRGBColorType(color || "pink")} / 60%);
  }
  `

styleVars.buttonAnimation = {
  desplode: (color?: ButtonColor) =>
    css`
      ${BtnDesplodeAnim(color)}
    `,
  explode: (color?: ButtonColor) =>
    css`
      ${BtnExplodeAnim(color)}
    `,
}

export const handleColorType = (color: string, dark?: boolean) => {
  switch (color) {
    case "pink":
      return !dark ? styleVars.colors.pink : styleVars.colors.darkPink
    case "green":
      return !dark ? styleVars.colors.seaGreen : styleVars.colors.darkSeaGreen
    case "sage-green":
      return !dark ? styleVars.colors.sageGreen : styleVars.colors.darkSageGreen
    case "purple":
      return !dark ? styleVars.colors.purple : styleVars.colors.darkPurple
    case "black":
      return !dark ? styleVars.colors.textMain : "#000"
    case "grey":
      return styleVars.colors.bgGrey
    case "lightGrey":
      return styleVars.colors.grayishSeventh
    case "whiteBlue":
      return "white"
    case "whiteDarkBlue":
      return "white"
    case "fadedPink":
      return styleVars.colors.fadedPink
    case "darkBlue":
      return styleVars.colors.darkBlue
    case "darken":
      return "rgba(0, 0, 0, 0.2)"
    default:
      return !dark ? styleVars.colors.skyBlue : styleVars.colors.darkBlue
  }
}

export const handleRGBColorType = (color: string, dark?: boolean) => {
  switch (color) {
    case "pink":
      return !dark ? styleVars.colors.pinkRGB : styleVars.colors.darkPinkRGB
    case "green":
      return !dark
        ? styleVars.colors.seaGreenRGB
        : styleVars.colors.darkSeaGreenRGB
    case "black":
      return !dark ? styleVars.colors.textMainRGB : "0,0,0"
    case "grey":
      return styleVars.colors.bgGreyRGB
    case "whiteBlue":
      return styleVars.colors.snowWhiteRGB
    case "whiteDarkBlue":
      return styleVars.colors.snowWhiteRGB
    case "fadedPink":
      return styleVars.colors.fadedPinkRGB
    default:
      return !dark ? styleVars.colors.skyBlueRGB : styleVars.colors.darkBlueRGB
  }
}

export default styleVars
